import * as React from "react";

import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from "@shared-ui/experiment-context";
import { useDialog } from "@shared-ui/dialog-context";

import {
  Map,
  PropertySpaceOverview,
  PropertySummaryHeader,
  PropertySummaryNearByPois,
  PropertySummaryMapTrigger,
  PropertySummaryVipBadge,
  ProductHighlights,
} from "@shared-ui/lodging-property-details";
import { RelevantContentModule } from "../components/product/RelevantContentWrapper";

import { PropertyFilteredReviewsDialog } from "components/shared/PropertyFilteredReviewsDialog/PropertyFilteredReviewsDialog";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";
import { PropertySummaryProps } from "../typings";

import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkSheetTransition } from "@egds/react-core/sheet";

import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { ProductRatingSummary } from "@shared-ui/retail-product-rating-summary";
import { AmenitiesModule } from "../components/product/AmenitiesWrapper";
import { CheckInOutModule } from "../components/product/CheckInOutWrapper";
import { LocationSection } from "@shared-ui/retail-product-location-section";

const WrappedPropertyMap = PropertyBexApiWrapper(Map);
const WrappedPropertySpaceOverview = PropertyBexApiWrapper(PropertySpaceOverview);
const WrappedSummaryHeader = PropertyBexApiWrapper(PropertySummaryHeader);
const WrappedSummaryNearByPois = PropertyBexApiWrapper(PropertySummaryNearByPois);
const WrappedSummaryMapTrigger = PropertyBexApiWrapper(PropertySummaryMapTrigger);
const WrappedProductRatingSummary = ProductBexApiWrapper(ProductRatingSummary);
const WrappedPropertySummaryVipBadge = PropertyBexApiWrapper(PropertySummaryVipBadge);
const WrappedProductLocationSection = ProductBexApiWrapper(LocationSection);
const WrappedProductHighlights = ProductBexApiWrapper(ProductHighlights);

export const PropertySummarySingleOffer: React.FC<PropertySummaryProps> = ({ context, templateComponent }) => {
  const id = templateComponent?.metadata?.id || "";
  const { hideHeader = false, showVipBadge = false, showHighlights = true } = templateComponent?.config || {};
  const repositioningMapAndLocationInsightsToAboutTheAreaVariant =
    useExperiment("Repositioning_map_and_location_insights_to_About_the_area").exposure.bucket === 1;

  const [isReviewsDialogOpen, reviewsDialogActions, ReviewsDialogComponent] = useDialog(`summary-reviews-${id}`);
  const [isMapDialogOpen, mapDialogActions, MapDialogComponent] = useDialog(`summary-map-${id}`);
  const triggerRef = React.useRef<HTMLElement>(null);

  /* istanbul ignore next */
  const onClickReviews = reviewsDialogActions.openDialog;
  /* istanbul ignore next */
  const onClickMap = mapDialogActions.openDialog;

  return (
    <>
      <UitkSpacing margin={{ blockend: "six", blockstart: "half" }}>
        <UitkCard>
          <UitkCardContentSection>
            <UitkSpacing padding={{ large: { inline: "three", block: "three" } }}>
              <UitkLayoutFlex direction="column">
                <>
                  <UitkLayoutFlexItem>
                    {!hideHeader && (
                      <UitkSpacing
                        padding={{ blockend: "three" }}
                        margin={{ small: { blockstart: "three" }, medium: { blockstart: "unset" } }}
                      >
                        <div data-testid="content-hotel-title">
                          <WrappedSummaryHeader context={context} />
                        </div>
                      </UitkSpacing>
                    )}
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <div>
                      {showVipBadge && hideHeader && (
                        <UitkSpacing padding={{ blockend: "three" }}>
                          <div>
                            <WrappedPropertySummaryVipBadge context={context} />
                          </div>
                        </UitkSpacing>
                      )}
                      <UitkSpacing padding={{ blockend: "three" }} margin={{ blockstart: "three" }}>
                        <WrappedProductRatingSummary context={context} onClickReviews={onClickReviews} />
                      </UitkSpacing>
                    </div>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    {showHighlights && <WrappedProductHighlights context={context} />}
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <UitkSpacing padding={{ blockend: "three" }} margin={{ blockstart: "three" }}>
                      <WrappedPropertySpaceOverview context={context} />
                    </UitkSpacing>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <UitkSpacing padding={{ small: { blockend: "three" } }} margin={{ blockstart: "three" }}>
                      <AmenitiesModule context={context} />
                    </UitkSpacing>
                  </UitkLayoutFlexItem>
                  <RelevantContentModule context={context} />
                  <div>
                    <CheckInOutModule context={context} />
                  </div>
                  {!repositioningMapAndLocationInsightsToAboutTheAreaVariant && (
                    <UitkLayoutFlexItem>
                      <Experiment name="PDP_LOCATION_SECTION_MIGRATION_WEB">
                        <ExperimentControl>
                          <div>
                            <Viewport>
                              <ViewSmall>
                                <>
                                  <div data-testid="map-image-link">
                                    <UitkSpacing margin={{ blockstart: "three" }}>
                                      <WrappedSummaryMapTrigger
                                        context={context}
                                        onClickMap={onClickMap}
                                        aspectRatio={UitkFigureAspectRatioType.R21_9}
                                      />
                                    </UitkSpacing>
                                  </div>
                                  <div data-testid="location-summary">
                                    <UitkSpacing margin={{ blockstart: "three" }}>
                                      <WrappedSummaryNearByPois context={context} />
                                    </UitkSpacing>
                                  </div>
                                </>
                              </ViewSmall>
                              <ViewMedium>
                                <UitkLayoutGrid space="three" columns={2}>
                                  <UitkLayoutGridItem>
                                    <div data-testid="map-image-link">
                                      <UitkSpacing margin={{ blockstart: "three" }}>
                                        <WrappedSummaryMapTrigger
                                          context={context}
                                          onClickMap={onClickMap}
                                          aspectRatio={UitkFigureAspectRatioType.R21_9}
                                        />
                                      </UitkSpacing>
                                    </div>
                                  </UitkLayoutGridItem>
                                  <UitkLayoutGridItem>
                                    <div data-testid="location-summary">
                                      <UitkSpacing margin={{ blockstart: "three" }}>
                                        <WrappedSummaryNearByPois context={context} />
                                      </UitkSpacing>
                                    </div>
                                  </UitkLayoutGridItem>
                                </UitkLayoutGrid>
                              </ViewMedium>
                            </Viewport>
                          </div>
                        </ExperimentControl>
                        <ExperimentVariant bucket={1}>
                          <WrappedProductLocationSection context={context} />
                        </ExperimentVariant>
                      </Experiment>
                    </UitkLayoutFlexItem>
                  )}
                </>
              </UitkLayoutFlex>
            </UitkSpacing>
          </UitkCardContentSection>
        </UitkCard>
      </UitkSpacing>
      <UitkSheetTransition isVisible={isReviewsDialogOpen}>
        <ReviewsDialogComponent>
          <PropertyFilteredReviewsDialog context={context} onClose={reviewsDialogActions.closeDialog} />
        </ReviewsDialogComponent>
      </UitkSheetTransition>
      <UitkSheetTransition isVisible={isMapDialogOpen}>
        <MapDialogComponent>
          <WrappedPropertyMap context={context} onClose={mapDialogActions.closeDialog} triggerRef={triggerRef} />
        </MapDialogComponent>
      </UitkSheetTransition>
    </>
  );
};

PropertySummarySingleOffer.displayName = "PropertySummarySingleOffer";
export default PropertySummarySingleOffer;
